<template>
  <v-text-field
    id="limit"
    dense
    name="limit"
    label="Limit"
    outlined
    persistent-placeholder
    type="number"
    :error-messages="errorMessages"
    v-model="limit"
    @blur="v.$touch()"
    @input="v.$touch()"
  ></v-text-field>
</template>

<script>
import { API } from '@/constants';

export default {
  name: 'ParamLimit',
  props: {
    value: {
      type: String,
      default: '',
    },
    v: {
      type: Object,
    },
  },
  emits: ['input'],
  computed: {
    limit: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    errorMessages() {
      const errors = [];
      if (!this.v || !this.v.$dirty) return errors;
      !this.v.between &&
        errors.push(
          `The limit must be greater than 0 and less than ${API.PARAM.MAX_LIMIT}`,
        );
      return errors;
    },
  },
};
</script>
