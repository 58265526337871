<template>
  <v-list-item :to="to">
    <v-list-item-icon>
      <v-icon color="primary">
        {{ mdiChevronRight }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        <span v-text="title" />
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mdiChevronRight } from '@mdi/js';

export default {
  name: 'BaseLinkItem',
  props: {
    title: {
      type: String,
      required: true,
      note: "The item's label",
    },
    to: {
      type: Object,
      required: true,
      note: "The item's link",
    },
  },
  data() {
    return {
      mdiChevronRight,
    };
  },
};
</script>
