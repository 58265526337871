<template>
  <v-btn
    block
    color="primary"
    depressed
    :disabled="disabled"
    :loading="loading"
    @click="execute"
  >
    <span v-text="'Execute'" />
  </v-btn>
</template>

<script>
export default {
  name: 'TheExecuteButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
      note: 'Whether the button is disabled',
    },
    execute: {
      type: Function,
      required: true,
      note: 'The function to execute',
    },
    loading: {
      type: Boolean,
      default: false,
      note: 'Whether the button is loading',
    },
  },
};
</script>
