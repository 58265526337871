import { CONFIG, ROUTE } from '@/constants';

const loginRoute = {
  path: ROUTE.PATH.LOGIN,
  meta: {
    auth: ROUTE.AUTH.USER_ROLE.ANY,
  },
  beforeEnter: () => {
    const location = `${CONFIG.KEYCLOAK.URL}/realms/${CONFIG.KEYCLOAK.REALM}/protocol/openid-connect/auth?client_id=${CONFIG.KEYCLOAK.CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${CONFIG.HOST}`;
    window.location = location;
  },
};

export default loginRoute;
