<template>
  <v-select
    dense
    item-text="name"
    item-value="denom"
    label="Select"
    outlined
    return-object
    :items="balances"
    v-model="balance"
  >
    <template #item="data">
      <span class="text-caption">
        {{ data.item.name }}
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'SendBalance',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    balances: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input'],
  computed: {
    balance: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
