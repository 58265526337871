import { nftApi } from '@/apis';

export default {
  initNft({ commit }) {
    commit('reset');
  },
  async fetchList({ commit, rootGetters }, params) {
    const token = rootGetters['token'];
    commit('setLoading', true);
    try {
      const response = await nftApi.requestList(token, params);
      commit('setResponse', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchDetail({ commit, rootGetters }, id) {
    const token = rootGetters['token'];
    commit('setLoading', true);
    try {
      const response = await nftApi.requestDetail(token, id);
      commit('setResponse', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  async initNftTransfer({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchAllNftTokens')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchAllNftTokens({ commit, rootGetters }) {
    const token = rootGetters['token'];
    const nftTokens = [];
    let cursor = '';
    do {
      try {
        const response = await nftApi.requestList(token, { cursor });
        nftTokens.push(...response.data.tokens);
        cursor = response.data.pagination.next_cursor;
      } catch (error) {
        commit('setError', error);
      }
    } while (cursor);
    commit('setTokens', nftTokens);
  },
  async transferNft({ commit, rootGetters }, data) {
    const token = rootGetters['token'];
    commit('setSending', true);
    try {
      const response = await nftApi.requestTransfer(token, data);
      commit('setResponse', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setSending', false);
    }
  },
};
