import { ROUTE } from '@/constants';
import { loadView } from '../index';

const UsersDetailView = loadView('users/UsersDetailView');
const UsersCreateView = loadView('users/UsersCreateView');

const usersRoute = {
  path: ROUTE.PATH.USERS,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.USERS,
      component: UsersDetailView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.USERS_DETAIL,
      name: ROUTE.NAME.USERS_DETAIL,
      component: UsersDetailView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.USERS_CREATE,
      name: ROUTE.NAME.USERS_CREATE,
      component: UsersCreateView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
  ],
};

export default usersRoute;
