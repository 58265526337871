<template>
  <v-row>
    <v-col cols="12" md="4" offset-md="4">
      <v-form>
        <send-balance :balances="balances" v-model="model.balance" />
        <send-amount :balance="model.balance" v-model.trim="model.amount" />
        <base-text-input
          name="recipient"
          label="Recipient"
          v-model.trim="model.recipient"
        />
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import SendAmount from './send-tokens/SendAmount.vue';
import SendBalance from './send-tokens/SendBalance.vue';

export default {
  name: 'TheSendTokensForm',
  components: {
    SendAmount,
    SendBalance,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    balances: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input'],
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
