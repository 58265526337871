import { usersApi } from '@/apis';

export default {
  initUsers({ commit }) {
    commit('reset');
  },
  async fetchDetail({ commit, rootGetters }) {
    const token = rootGetters['token'];
    commit('setLoading', true);
    try {
      const response = await usersApi.requestDetail(token);
      commit('setResponse', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  async createUser({ commit, rootGetters }) {
    const token = rootGetters['token'];
    commit('setLoading', true);
    try {
      const response = await usersApi.requestCreate(token);
      commit('setResponse', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
};
