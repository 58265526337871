<template>
  <v-card color="red lighten-5" flat>
    <v-card-title>
      <div v-text="'Error'" />
    </v-card-title>
    <v-card-text>
      <div>
        <span class="mr-1 font-weight-bold" v-text="'Message'" />
        <span v-text="processedError.data.error" />
      </div>
      <div>
        <span class="mr-1 font-weight-bold" v-text="'Code'" />
        <span v-text="processedError.status" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { errorHandler } from '@/utils';

export default {
  name: 'BaseErrorMessage',
  props: {
    error: {
      type: Error,
      required: true,
      note: 'The error to display',
    },
  },
  computed: {
    processedError() {
      return errorHandler.process(this.error);
    },
  },
};
</script>
