<template>
  <v-sheet color="rgba(0,0,0,0)">
    <v-card flat outlined :loading="loading">
      <template slot="progress">
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </template>
      <v-card-title class="d-flex justify-center">
        <div v-text="title" />
      </v-card-title>
      <v-card-subtitle>
        <slot name="description" />
      </v-card-subtitle>
      <v-card-text>
        <slot name="form" />
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="4" offset="4">
            <slot name="execute" />
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-text>
        <slot name="response" />
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  name: 'TheFramedWindow',
  props: {
    title: {
      type: String,
      required: true,
      note: 'The window title',
    },
    loading: {
      type: Boolean,
      default: false,
      note: 'The loading state',
    },
  },
};
</script>
