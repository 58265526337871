import { ROUTE } from '@/constants';
import { loadView } from '../index';

const NftDetailView = loadView('nft/NftDetailView');
const NftListView = loadView('nft/NftListView');
const NftTransferView = loadView('nft/NftTransferView');

const nftRoute = {
  path: ROUTE.PATH.NFT,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.NFT,
      component: NftListView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.NFT_DETAIL,
      name: ROUTE.NAME.NFT_DETAIL,
      component: NftDetailView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.NFT_LIST,
      name: ROUTE.NAME.NFT_LIST,
      component: NftListView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.NFT_TRANSFER,
      name: ROUTE.NAME.NFT_TRANSFER,
      component: NftTransferView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
  ],
};

export default nftRoute;
