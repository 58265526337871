<template>
  <div>
    <div class="text-right text-caption info--text" v-text="caption" />
    <v-text-field
      id="amount"
      dense
      name="amount"
      label="Amount"
      outlined
      step="0.01"
      type="number"
      :error-messages="errorMessages"
      v-model="amount"
    ></v-text-field>
  </div>
</template>

<script>
import { tokensHandler } from '@/utils';

export default {
  name: 'SendAmount',
  props: {
    value: {
      type: String,
      default: '',
    },
    balance: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['input'],
  computed: {
    amount: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    balanceAmount() {
      return this.balance
        ? tokensHandler.convertFromBase(
            this.balance.amount,
            this.balance.decimals,
          )
        : 0;
    },
    caption() {
      return this.balanceAmount
        ? tokensHandler.format(this.balanceAmount, this.balance.decimals)
        : '-';
    },
    errorMessages() {
      const errors = [];
      if (parseFloat(this.amount) > this.balanceAmount) {
        errors.push('Insufficient funds');
      }
      if (parseFloat(this.amount) <= 0) {
        errors.push('Amount must be greater than 0');
      }
      return errors;
    },
  },
};
</script>
