<template>
  <v-row>
    <v-col cols="12" md="4" offset-md="4">
      <v-form>
        <base-text-input
          name="cursor"
          label="Cursor"
          v-model.trim="model.cursor"
        />
        <param-limit v-model.trim="model.limit" :v="$v.model.limit" />
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import ParamLimit from './params/ParamLimit.vue';

import { between } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { API } from '@/constants';

export default {
  name: 'TheParamsForm',
  components: {
    ParamLimit,
  },
  mixins: [validationMixin],
  validations() {
    return {
      model: {
        limit: {
          between: between(1, API.PARAM.MAX_LIMIT),
        },
      },
    };
  },
  data() {
    return {
      model: {
        cursor: '',
        limit: '0',
      },
    };
  },
  watch: {
    model: {
      handler(newValue) {
        this.$emit('new-params', {
          isValid: !this.$v.$invalid,
          params: newValue,
        });
      },
      deep: true,
    },
  },
  created() {
    this.model.limit = API.PARAM.LIMIT.toString();
  },
};
</script>
