import { ROUTE } from '@/constants';
import { loadView } from '../index';

const BankBalanceView = loadView('bank/BankBalanceView');
const BankReceivedView = loadView('bank/BankReceivedView');
const BankSendView = loadView('bank/BankSendView');
const BankSentView = loadView('bank/BankSentView');

const bankRoute = {
  path: ROUTE.PATH.BANK,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.BANK,
      component: BankBalanceView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.BANK_BALANCE,
      name: ROUTE.NAME.BANK_BALANCE,
      component: BankBalanceView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.BANK_RECEIVED,
      name: ROUTE.NAME.BANK_RECEIVED,
      component: BankReceivedView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.BANK_SEND,
      name: ROUTE.NAME.BANK_SEND,
      component: BankSendView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.BANK_SENT,
      name: ROUTE.NAME.BANK_SENT,
      component: BankSentView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
  ],
};

export default bankRoute;
