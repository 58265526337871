<template>
  <v-row>
    <v-col cols="12" md="4" offset-md="4">
      <v-form>
        <base-text-input name="id" label="ID" v-model.trim="model.id" />
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TheItemIdForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['input'],
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
