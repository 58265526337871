import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, payload) {
    state.error = payload;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setProfile(state, payload) {
    state.profile = payload;
  },
  setToken(state, payload) {
    state.token = payload;
  },
};
