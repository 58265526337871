import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import bank from './bank';
import cw20 from './cw20';
import nft from './nft';
import users from './users';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    bank,
    cw20,
    nft,
    users,
  },
});
