const errorHandler = {
  process(error) {
    return error.response
      ? composeWithResponse(error)
      : composeNoResponse(error);
  },
};

export default errorHandler;

/**
 *
 * @param {Error} error
 * @returns {Object}
 */
const composeWithResponse = (error) => error.response;

/**
 * @param {Error} error
 * @returns {Object}
 */
const composeNoResponse = (error) => ({
  data: {
    error: error.request
      ? 'The request was made but no response was received'
      : 'Something happened in setting up the request that triggered an error',
  },
  status: 503,
});
