import { bankApi } from '@/apis';
import { API } from '@/constants';

export default {
  initBank({ commit }) {
    commit('reset');
  },
  async fetchBalance({ commit, rootGetters }) {
    const token = rootGetters['token'];
    commit('setLoading', true);
    try {
      const response = await bankApi.requestBalance(token);
      commit('setResponse', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchReceived({ commit, rootGetters }, params) {
    const token = rootGetters['token'];
    commit('setLoading', true);
    try {
      const response = await bankApi.requestReceived(token, params);
      commit('setResponse', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchSent({ commit, rootGetters }, params) {
    const token = rootGetters['token'];
    commit('setLoading', true);
    try {
      const response = await bankApi.requestSent(token, params);
      commit('setResponse', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  async initBankSend({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchUserBalances')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchUserBalances({ commit, rootGetters }) {
    const token = rootGetters['token'];
    const params = { token_type: API.PARAM.TYPE.NATIVE };
    try {
      const response = await bankApi.requestBalance(token, params);
      commit('setBalances', response.data.balance);
    } catch (error) {
      commit('setError', error);
    }
  },
  async sendTokens({ commit, rootGetters }, data) {
    const token = rootGetters['token'];
    commit('setSending', true);
    try {
      const response = await bankApi.requestSend(token, data);
      commit('setResponse', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setSending', false);
    }
  },
};
